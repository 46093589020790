@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

.container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #E8E8E8;
    overflow: hidden;
    z-index: 3;
}

.leftcontainer {
    display: inline-block;
    width: 40%;
    height: 100%;
    background-color: #111216;
    color: #E8E8E8;
    font-weight: 200;
    font-size: 2.75em;
    background-image: url("../../images/sky2.png");
    background-position: center;
    background-size: 27vw;
    background-position-y: 22vh;
    background-repeat: no-repeat;
    margin: 0;
    transition: width 1s ease;
}

.rightcontainer {
    display: inline-block;
    position: absolute;
    width: 60%;
    height: 100%;
    top: 0;
    transition: opacity 1s ease;
    z-index: 12;
}

.welcome {
    position: relative;
    font-size: 0.65em;
}

.welcome2 {
    display: none;
}

.information {
    position: relative;
    width: fit-content;
    left: 50%;
    top: 60.2%;
    transform: translate(-50%, -50%);
}

.head {
    position: relative;
    display: flex;
    left: 50%;
    top: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
}

.head h1 {
    z-index: 6;
    font-family: "lexend exa";
    font-size: 1em;
    padding: 0.15rem;
}

.back {
    font-size: 0.75em;
    position: absolute;
    padding: 0.5vw;
}

.form {
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
}

.formleft {
    top: 8vh;
    position: relative;
    width: 100%;
}

.formright {
    top: 8vh;
    position: relative;
    width: 110%;
}

textarea {
    resize: none;
}

.submit {
    font-family: 'Lexend Exa';
    font-weight: 400;
    bottom: 1em;
    position: absolute;
    color: #374151;
    font-size: 0.75em;
    right: 14vw;
    background-color: rgb(202, 218, 225) !important;
    border-radius: 5px;
    padding: 10px;
}

.submit:hover {
    cursor: pointer;
    color: #212730;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-height: 500px) and (orientation: landscape) {
    .leftcontainer {
        width: 100%;
        background-size: 100vh;
        background-position-y: 10vh;
    }
    
    .rightcontainer {
        display: none;
    }

    .head {
        top: 60%;
        font-size: 0.7em;
    }

    .welcome {
        display: none;
    }

    .welcome2 {
        display: block;
        padding-top: 6em;
        font-size: 0.35em; 
        transition: none;
        text-align: center;
        width: 95%;
        padding-left: 5%;
        color: #ea6969;
    }
}

@media screen and (max-width: 720px) and (orientation: portrait) {
    .leftcontainer {
        width: 100%;
        background-size: 110vw;
    }
    
    .rightcontainer {
        display: none;
    }

    .head {
        font-size: 0.7em;
    }

    .welcome {
        display: none;
    }

    .welcome2 {
        display: block;
        padding-top: 6em;
        font-size: 0.35em; 
        transition: none;
        text-align: center;
        width: 95%;
        padding-left: 5%;
        color: #ea6969;
    }
}